<template>
    <v-container fluid>
        <v-card>
            <v-card-title>
                <v-row align="center">
                    <v-col cols="12" lg="4">
                        Match
                    </v-col>
                    <v-col cols="12">
                        <match-filters @setFilters="setFilters"/>
                    </v-col>
                    <v-col cols="12" align="end">
                        <v-btn class="my-2 mr-2" dark small color="black" @click="createMatch">
                            <v-icon dark>mdi-plus</v-icon>Create
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
        </v-card>
        <array-view
            :items="matches"
            :headers="displayInfo"
            :total-pages="totalPages"
            :page="page"
            :size="size"
            @onDelete="onDelete"
            @handleClick="handleClick"
            @paginationChange="paginationChange"
            :type="'match'"
        />
    </v-container>
</template>

<script>
    import matchService from '@/services/match'
    import ArrayView from '@/components/ArrayView'
    import MatchFilters from '@/components/MatchFilters'

    export default {
        name: 'Match',
        components: { ArrayView, MatchFilters },
        data: () => {
            return {
                successfullImport: null,
                showImportDialog: false,
                matches: [],
                displayInfo: [
                    {
                        text: 'Id',
                        value: 'id'
                    },
                    {
                        text: 'Team 1',
                        value: 'team1',
                        subLevel: 'name'
                    },
                    {
                        text: 'Team 2',
                        value: 'team2',
                        subLevel: 'name'
                    },
                    {
                        text: 'Score Team 1',
                        value: 'scoreTeam1'
                    },
                    {
                        text: 'Score Team 2',
                        value: 'scoreTeam2'
                    },
                    {
                        text: 'Season',
                        value: 'season',
                        subLevel: 'name'
                    },
                    {
                        text: 'Date',
                        value: 'matchDatetime'
                    },
                    {
                        text: 'Phase',
                        value: 'phase'
                    },
                    {
                        text: 'Type',
                        value: 'season',
                        subLevel: 'type'
                    },
                    {
                        text: 'Cup',
                        value: 'isCup'
                    },
                    {
                        text: 'League',
                        value: 'league'
                    },
                    {
                        text: 'Round',
                        value: 'round'
                    }
                ],
                size: 5,
                totalPages: 0,
                page: 1,
                filters: null,
                loading: false
            }
        },
        watch: {
            filters: {
                handler() {
                    this.fetchFilteredMatches()
                }
            }
        },
        created() {
            this.filters = this.$store.getters.getMatchFilters
            this.page = this.$store.getters.getPage
            this.fetchFilteredMatches()
        },
        methods: {
            paginationChange(page, size) {
                this.size = size
                this.page = page
                this.$store.dispatch('setPage', page)
                this.fetchFilteredMatches()
            },
            handleClick(action, item) {
                this.$router.push(`/matches/${item.id}/${action}`)
            },
            onDelete(item, page) {
                matchService.deleteMatch(item.id).then(() => {
                    this.page = page
                    this.fetchFilteredMatches()
                })
            },
            setFilters(filters) {
                this.filters = filters
                this.$store.dispatch('setMatchFilters', this.filters)
            },
            createMatch() {
                this.$router.push(`/matches/create`)
            },
            fetchFilteredMatches() {
                this.loading = true
                matchService.getFilteredMatches(this.filters, this.page, this.size).then(resp => {
                    this.matches = resp.data.content
                    this.totalPages = resp.data.totalPages
                    this.loading = false
                })
            }
        }
    }
</script>

<style scoped>
.card-title {
    display: flex;
}

.v-list-item {
    min-height: 30px !important;
}

.v-card {
    min-width: 200px;
    position: relative;
}

.header-card {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1%;
}

.header-buttons {
    align-self: center;
}

.edit-button:hover {
    background-color: whitesmoke;
    color: white;
}

.promo-category-card {
    padding: 4%;
}

.overflow-text {
    max-width: 79%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.pagination {
    justify-content: center;
}
</style>
