<template>
    <v-expansion-panels v-model="panel">
        <v-expansion-panel>
            <v-expansion-panel-header class="card-title mt-1">
                Filters
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-row>
                    <v-col cols="12" lg="3">
                        <v-text-field
                            v-model="round"
                            menu-props="auto"
                            label="Round"
                            hide-details
                            type="Number"
                            clearable
                            return-object
                        />
                    </v-col>
                    <v-col cols="12" lg="3">
                        <v-autocomplete
                            v-model="season"
                            :items="seasons"
                            item-text="name"
                            item-value="id"
                            menu-props="auto"
                            label="Season"
                            hide-details
                            clearable
                            return-object
                        />
                    </v-col>
                    <v-col cols="12" lg="3">
                        <v-autocomplete
                            v-model="phase"
                            :items="['placements', 'regular_season', 'playoffs']"
                            menu-props="auto"
                            label="Phase"
                            hide-details
                            clearable
                            return-object
                        />
                    </v-col>
                    <v-col cols="12" lg="3">
                        <v-select
                            v-model="cup"
                            :items="[true, false]"
                            label="Cup"
                            clearable
                        />
                    </v-col>
                    <v-col cols="12" lg="3">
                        <v-autocomplete
                            v-model="homeTeam"
                            :items="teams"
                            menu-props="auto"
                            label="Home Team"
                            item-text="name"
                            hide-details
                            clearable
                            return-object
                        />
                    </v-col>
                    <v-col cols="12" lg="3">
                        <v-autocomplete
                            v-model="awayTeam"
                            :items="teams"
                            menu-props="auto"
                            label="Away Team"
                            item-text="name"
                            hide-details
                            clearable
                            return-object
                        />
                    </v-col>
                    <v-col cols="12" lg="3" align="end">
                        <v-btn class="my-2 mr-2" dark small color="black" @click="clearFilters">
                            <v-icon dark>mdi-close</v-icon>Clear filters
                        </v-btn>
                    </v-col>
                    <v-col cols="12" lg="3" align="start">
                        <v-btn class="my-2 mr-2" dark small color="black" @click="applyFilters">
                            <v-icon dark>mdi-filter</v-icon>Apply filters
                        </v-btn>
                    </v-col>
                </v-row>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
    import seasonService from '@/services/season'
    import teamService from '@/services/team'
    export default {
        name: 'MatchFilters',
        data: () => {
            return {
                round: null,
                season: null,
                phase: null,
                cup: null,
                seasons: [],
                homeTeam: null,
                awayTeam: null,
                teams: [],
                filters: null
            }
        },
        watch: {
            filters: {
                handler() {
                    this.setFilters()
                }
            }
        },
        created() {
            this.filters = this.$store.getters.getMatchFilters
            this.fetchAllSeasons()
            this.fetchAllTeams()
        },
        methods: {
            setFilters() {
                this.filters = decodeURIComponent(this.filters)
                this.filters = this.filters.replace(/[|&;$%@<>()+=?]/g, '').replace(/filter/g, '')
                let query = JSON.parse(this.filters)
                for (const property in query) {
                    switch (property) {
                    case 'round':
                        this.round = query[property]
                        break
                    case 'phase':
                        this.phase = query[property]
                        break
                    case 'cup':
                        this.cup = query[property]
                        break
                    case 'team1':
                        this.homeTeam = this.teams.find(team => team.id === parseInt(query[property]))
                        break
                    case 'team2':
                        this.awayTeam = this.teams.find(team => team.id === parseInt(query[property]))
                        break
                    case 'season':
                        this.season = this.seasons.find(season => season.id === parseInt(query[property]))
                        break
                    default:
                        console.log('there is no such filter')
                    }
                }
            },
            fetchAllSeasons() {
                seasonService.getSeasons().then(resp => {
                    this.seasons = resp.data.content
                    this.setFilters()
                })
            },
            fetchAllTeams() {
                teamService.getTeams().then(resp => {
                    this.teams = resp.data.content
                    this.setFilters()
                })
            },
            clearFilters() {
                this.round = null
                this.season = null
                this.cup = null
                this.phase = null
                this.filters = null
                this.homeTeam = null
                this.awayTeam = null
                this.$emit('setFilters', null)
            },
            applyFilters() {
                let query = ''
                if (this.round) {
                    query += `"round":${this.round},`
                }
                if (this.season) {
                    query += `"season":"${this.season.id}",`
                }
                if (this.phase) {
                    query += `"phase":"${this.phase}",`
                }
                if (this.homeTeam) {
                    query += `"team1":"${this.homeTeam.id}",`
                }
                if (this.awayTeam) {
                    query += `"team2":"${this.awayTeam.id}",`
                }
                if (this.cup !== null) {
                    query += `"isCup":${this.cup},`
                }
                this.filters = `?filter=${encodeURIComponent('{' + query.slice(0, -1) + '}')}`
                this.$emit('setFilters', this.filters)
            }
        }
    }
</script>
